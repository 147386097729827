import { createStore } from "vuex";
import elearning from "./elearning/index.js";
import information from "./information/index.js";
import checkUser from "./checkUser/index.js";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    elearning,
    information,
    checkUser,
  },
});
