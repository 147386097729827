import _axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    _list_subject: null,
  },
  getters: {
    list_subject: (state) => state._list_subject,
  },
  mutations: {},
  actions: {
    list_subject(context) {
      _axios.get("/elearning/_list_subject").then((res) => {
        context.state._list_subject = res.data;
      });
    },
  },
  modules: {},
};
