import _axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    _list_news: null,
    _list_teacher: null,
  },
  getters: {
    _list_news: (state) => state._list_news,
    _list_teacher: (state) => state._list_teacher,
    _list_news_type: (state) => state._list_news_type,
  },
  mutations: {},
  actions: {
    //-----------------List Data
    _list_news(context, param) {
      return _axios.post("/information/_list_news", param).then((res) => {
        context.state._list_news = res.data;
        return res.data;
      });
    },
    _list_news_type(context) {
      _axios.get("/information/_list_news_type").then((res) => {
        context.state._list_news_type = res.data;
      });
    },
    _list_teacher(context) {
      _axios.get("/information/_list_teacher").then((res) => {
        context.state._list_teacher = res.data;
      });
    },
    _check_user_website(context) {
      return _axios.get("/user_website/_checkUserWebsite").then((res) => res);
    },
    //-----------------List Data
    //-----------------Insert Data
    _insert_news(context, params) {
      return _axios
        .post("/information/_insert_news", params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => res.data);
    },
    //-----------------Insert Data
    //-----------------Edit Data
    //-----------------Edit Data
    //-----------------Delete Data
    //-----------------Delete Data
  },
  modules: {},
};
