import _axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    _list_userWebsite: null,
  },
  getters: {
    list_userWebsite: (state) => state._list_userWebsite,
  },
  mutations: {},
  actions: {
    _checkUserWebsite(context, params) {
      return _axios
        .post("/checkUser/_checkUserWebsite", params)
        .then((res) => res.data);
    },
  },
  modules: {},
};
