// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import { VDateInput } from "vuetify/labs/VDateInput";

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  locale: {
    locale: "th",
  },
  date: {
    locale: {
      en: "th-TH",
    },
  },
  components: {
    VDateInput,
  },
});
