<template>
  <v-dialog max-width="1200">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
        v-bind="activatorProps"
        prepend-icon="mdi-newspaper"
        text="ปฏิทินวิชาการ"
      ></v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card title="ปฏิทินวิชาการ">
        <v-card-text
          ><embed
            src="https://jm2.ac.th/img/calandar_academic.jpg"
            width="100%"
            height="100%"
            title="ปฏิทินวิชาการ"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text="Close Dialog" @click="isActive.value = false"></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style></style>
