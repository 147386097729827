<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :overlay="false"
    max-width="600px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title primary-title>
        <v-row
          ><v-col> เข้าสู่ระบบ </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-end">
            <v-icon @click="closeDialog(false)">mdi-close-circle</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-form
        ><v-card-text>
          <v-text-field
            label="User ID"
            v-model="userID"
            required
          ></v-text-field>
          <v-text-field
            label="User Pass"
            v-model="userPass"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit" variant="outlined" prepend-icon="mdi-login">
            เข้าสู่ระบบ
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      dialog: false,
      userID: "0000",
      userPass: "1234",
    };
  },
  methods: {
    closeDialog(value) {
      this.$emit("closeDialog", value);
    },
    submit() {
      this.closeDialog(false);
      this.$store
        .dispatch("checkUser/_checkUserWebsite", {
          userID: this.userID,
          userPass: this.userPass,
        })
        .then((res) => {
          // console.log(res[0]);
          if (res.length == 1) {
            Swal.fire(
              "ยินดีต้อนรับ",
              `${res[0].user_prename}${res[0].user_fname} ${
                res[0].user_lname
              }<br>เมื่อ ${Date.now()}`,
              "success"
            ).then((state) => {
              this.$router.push("/manages").then((res) => {});
            });
          } else {
            Swal.fire("Error", `กรุณาลองใหม่อีกครั้ง`, "error").then(
              (state) => {
                this.closeDialog(true);
              }
            );
          }
        });
    },
  },
};
</script>
<style></style>
