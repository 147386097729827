<template>
  <v-app>
    <v-app-bar :elevation="2" color="primary" scroll-behavior="hide">
      <template v-slot:prepend>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
      <v-app-bar-title>
        <router-link link to="/" class="no-link">
          <v-avatar>
            <v-img src="@/assets/images/logo.jpg"></v-img>
          </v-avatar>
          โรงเรียนเจ้าแม่หลวงอุปถัมภ์ ๒
        </router-link>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down">
        <v-menu open-on-hover>
          <template v-slot:activator="{ props }">
            <!-- <v-btn color="primary" > เกี่ยวกับโรงเรียน </v-btn> -->
            <v-btn prepend-icon="mdi-information-outline" v-bind="props">
              เกี่ยวกับโรงเรียน
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <router-link to="/about" class="no-link"
                  ><v-icon left>mdi-map-marker</v-icon>
                  ที่ตั้งโรงเรียน</router-link
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <router-link to="/resumeSchool" class="no-link"
                  ><v-icon left>mdi-file-account</v-icon>
                  ประวัติความเป็นมา</router-link
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <router-link to="/resumeBoss" class="no-link"
                  ><v-icon left>mdi-badge-account</v-icon>
                  ทำเนียบผู้บริหาร</router-link
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn prepend-icon="mdi-account-multiple"
          ><router-link to="/teachers" class="no-link">
            ครู และบุคลากรทางการศึกษา</router-link
          >
        </v-btn>
        <ShowNewsAcademic></ShowNewsAcademic>
        <v-btn prepend-icon="mdi-beach" to="/activity"> กิจกรรม </v-btn>
        <v-btn prepend-icon="mdi-card-account-phone-outline" to="/contextus">
          ติดต่อเรา
        </v-btn>
      </div>
      <v-btn icon @click="statFrmLogin = true">
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary>
      <v-list-item
        prepend-avatar="https://randomuser.me/api/portraits/men/78.jpg"
        title="John Leider"
      ></v-list-item>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <router-link to="/about">
          <v-list-item
            prepend-icon="mdi-information-outline"
            title="เกี่ยวกับโรงเรียน"
            value="about"
          ></v-list-item>
        </router-link>
        <v-list-item
          prepend-icon="mdi-account-multiple"
          title="บุคลากร"
          value="employee"
        ></v-list-item>
        <v-list-item> <ShowNewsAcademic></ShowNewsAcademic> </v-list-item>
        <router-link to="/activity">
          <v-list-item
            prepend-icon="mdi-beach"
            title="กิจกรรม"
            value="activity"
          ></v-list-item>
        </router-link>
        <v-list-item
          prepend-icon="mdi-card-account-phone-outline"
          title="ติดต่อเรา"
          value="contact"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <loginView v-model="statFrmLogin" @closeDialog="closeDialog"></loginView>
      <router-view />
      <Footer></Footer>
    </v-main>
  </v-app>
</template>

<script>
import loginView from "@/components/homeView/loginView.vue";
import ShowNewsAcademic from "@/components/homeView/showNewsAcademic.vue";
import Footer from "@/components/homeView/footer.vue";
export default {
  name: "App",
  components: {
    loginView,
    ShowNewsAcademic,
    Footer,
  },
  data: () => ({
    statFrmLogin: false,
    listMenu: [{ text: "", icon: "", link: "" }],
  }),
  methods: {
    closeDialog(value) {
      this.statFrmLogin = value;
    },
    stateUsAcademic(result) {
      this.stateShowNewsAcademic = result;
    },
  },
};
</script>
<script setup>
import { ref } from "vue";
import footerVue from "./components/homeView/footer.vue";

const drawer = ref(null);
</script>
<style>
/* kanit-regular - latin_thai */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  src: url("@/assets/fonts/kanit-v15-latin_thai-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
*,
html,
body {
  font-family: "Kanit";
}
.no-link {
  color: inherit !important; /* Remove default link color */
  text-decoration: none !important; /* Remove underline */
}
.image-with-watermark {
  position: relative;
}

.image-with-watermark::before {
  content: "© โรงเรียนเจ้าแม่หลวงอปุถัมภ์ ๒";
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: rgba(130, 243, 255, 0.254);
  font-weight: bold;
}
</style>
