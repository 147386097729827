<template>
  <v-footer class="bg-primary d-flex flex-column fixed" :app="true">
    <v-row>
      <v-col
        ><div class="pt-0">
          <v-container grid-list-xs>
            <v-row>
              <v-col
                >โรงเรียนเจ้าแม่หลวงอุปถัมภ์ 2 เลขที่ 88 หมู่ที่ 7
                บ้านหนองหอยเก่า ตำบลแม่แรม อำเภอแม่ริม จังหวัดเชียงใหม่
                50180</v-col
              >
            </v-row>
          </v-container>
        </div>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col
        ><div>
          <v-btn
            class="mx-4"
            variant="text"
            icon="mdi-facebook"
            href="https://www.facebook.com/www.cmlu2.ac.th"
          ></v-btn>
          <v-btn class="mx-4" variant="text" icon="mdi-youtube"></v-btn>
        </div>
        <div>
          {{ new Date().getFullYear() }} —
          <strong>Copy @ right </strong>
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {};
</script>
<style></style>
